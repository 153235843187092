<template>
    <div>
        <el-form :model="queryParams" ref="form" :inline="true" class="demo-form-inline" size="medium">
            <el-row>
                <el-row class="custom--top-btn-wrapper" type="flex" :gutter="20">
                    <el-col class="left-btn-group" :span="18">
                        <GgdCard class="custom--GgdCard" background="#409EFF" title="已领取/最大上限"
                                 :content="receiveStatus.receivedSize + '/' + receiveStatus.receiveMax"
                                 unit="条"></GgdCard>
                        <GgdCard class="custom--GgdCard" background="#F19149" title="领取等待"
                                 :content="waitTimeStr"></GgdCard>
                    </el-col>
                    <el-col class="right-btn-group" :offset="1" :span="6">
                        <el-button-group>
                            <el-button type="primary" size="large"
                                       @click="toHome()"
                                       :style="{margin:' 5px ',background:'red',
                     borderColor:'red',height:'62px',
                     borderRadius:'10px'}">
                                <i class="el-icon-switch-button" :style="{fontSize:'22px'}"></i><br>
                                回到主页
                            </el-button>
                            <el-button type="primary" size="large"
                                       @click="next"
                                       :style="{margin:' 5px ',background:'#6D88FD',borderColor:'#6D88FD',height:'62px',borderRadius:'10px'}">
                                <span style="font-size: 23px;color: #ffe554">点击刷新</span><br>
                                <span>剩余学员:{{ stockTotal }}</span>
                            </el-button>
                        </el-button-group>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="入库时间" prop="createTimeArr">
                        <el-date-picker
                                v-model="createTimeArr"
                                type="datetimerange"
                                align="right"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="doSearch">查询</el-button>
                        <el-button type="info" @click="restart">重置</el-button>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-table :data="stockTableData" stripe height="680" style="width: 100%" v-loading="loading">
                        <template v-slot:empty>当前暂无可领取数据，如存在剩余机会，请点击刷新</template>
                        <el-table-column prop="studentId" align="center" label="学员ID"></el-table-column>
                        <el-table-column prop="mobile" align="center" label="手机号"></el-table-column>
                        <el-table-column prop="createTime" align="center" label="首次入库时间"></el-table-column>
                        <el-table-column align="center" prop="name" label="操作" fixed="right" width="330">
                            <template v-slot="scope" v-if="!loading">
                                <el-button type="primary" size="mini" @click="handleView(scope.row)">
                                    查看手机
                                </el-button>
                                <el-tooltip class="item" effect="dark" content="直呼拨打" placement="top"
                                            v-if="callPanshi.qrcode === 'ON' && receive.calls">
                                    <el-button v-if="callPanshi.qrcode === 'ON'" type="success" size="mini"
                                               @click="calling($event,scope.row.studentId)">直呼
                                    </el-button>
                                </el-tooltip>
                                <el-button hidden type="danger" size="mini" @click="hangup($event)">挂断</el-button>
                                <el-button hidden type="warning" size="mini"
                                           @click="haveCalling($event,scope.row.studentId)">
                                    已拨打
                                </el-button>
                                <el-tooltip class="item" effect="dark" content="双呼拨打" placement="top"
                                            v-if="callDouble.qrcode === 'ON' && receive.calls">
                                    <el-button v-if="callDouble.qrcode === 'ON'" type="success" size="mini"
                                               @click="callDoubleing($event,scope.row.studentId)">双呼
                                    </el-button>
                                </el-tooltip>
                                <el-button type="primary" size="mini"
                                           @click="receiveStudent(scope.row.studentId, true)">
                                    {{ isShowGetBtn(scope.row.studentId) ? '领取' : '已领取' }}
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-row>
        </el-form>

        <el-dialog
                title="领取验证"
                :visible.sync="enableVerifyDialog"
                @closed="closeEnableVerifyDialog"
                :close-on-click-modal="false"
                width="30%">
            <el-row>
                <el-col :span="9">
                    <el-input v-model="captchaInput" placeholder="请输入验证码"/>
                </el-col>
                <el-col :span="1">
                    <el-divider direction="vertical"/>
                </el-col>
                <el-col :span="7">
                    <el-image :src="captchaSrc"></el-image>
                </el-col>
                <el-col :span="1">
                    <el-divider direction="vertical"/>
                </el-col>
                <el-col :span="5">
                    <el-button type="text" @click="beforeReceive(receiveStudentId)">看不清?点我换一张</el-button>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeEnableVerifyDialog">取 消</el-button>
                <el-button type="primary" @click="submitEnableVerifyDialog">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
import * as api from "@/utils/api";
import * as btnApi from '@/api/waiter/waiterReceiveBtn'
import GgdCard from "@/components/GgdCard.vue";
import * as studentApi from "@/api/waiter/waiterStudent";


export default {
    name: "StudentReceive",
    components: {
        GgdCard
    },
    data() {
        return {
            nextQuery: {
                btnType: 0
            }, //刷新所带参数
            queryParams: {
                dealTimeStart: '',
                dealTimeEnd: '',
                createTimeStart: '',
                createTimeEnd: '',
                wakeTimeEnd: '',
                wakeTimeStart: '',
                btnType: 0
            },
            dealTimeArr: [],
            createTimeArr: [],
            wakeTimeArr: [],
            receiveStatus: {
                receivedSize: 0,
                receiveMax: 0
            },
            waitTimeStr: '00:00:00',
            waitTime: 0,
            backStoreTimeStr: [],
            stockTableData: [],
            dayAccount: 0, //今日流水
            newClient: 0,//首咨数
            stockTotal: 0, //库存总量
            loading: true,
            callPanshi: {},//磐石云外呼按钮
            uploadTime: [],
            callDouble: {},//双呼外呼按钮,
            receive: {},
            // 倒计时定时器
            timeSetInterval: null,
            showGetBtn: [],
            enableVerifyDialog: false,
            captchaSrc: '',
            captchaInput: '',
            receiveStudentId: '',
            servicesId: null,
        }
    },
    watch: {
        waitTime: function (a, b) {
            this.showTime(a);
        }
    },
    beforeDestroy() {
        clearInterval(this.timeSetInterval)
    },
    methods: {
        restart() {
            this.type = 2;
            this.nextQuery = {};
            this.dealTimeArr = [];
            this.createTimeArr = [];
            this.wakeTimeArr = [];
            this.findReceiveStudent();
        },
        doSearch() {
            this.type = 1;
            this.queryParams.dealTimeStart = this.dealTimeArr[0]
            this.queryParams.dealTimeEnd = this.dealTimeArr[1]
            this.queryParams.createTimeStart = this.createTimeArr[0]
            this.queryParams.createTimeEnd = this.createTimeArr[1]
            this.queryParams.wakeTimeStart = this.wakeTimeArr[0]
            this.queryParams.wakeTimeEnd = this.wakeTimeArr[1]
            this.nextQuery = this.queryParams;
            // 搜索操作按钮的类型为1
            this.queryParams.btnType = 1
            this.findReceiveStudent()
        },
        getReceive() {
            btnApi.info(this.receiveId).then(res => {
                this.receive = res.data
            })
        },
        /**
         * 获取配置
         */
        Index() {
            // api.index().then(res => {
            //   console.log(res, '获取配置')
            //   this.callPanshi = res.data.callPanshi;
            //   this.callDouble = res.data.callDouble;
            // })
        },
        findStudent() {
            if (this.receiveId) {
                this.loading = true
                btnApi.findStudent(this.receiveId).then(res => {
                    if (res.success) {
                        this.stockTableData = res.data.data
                        this.stockTotal = res.data.total
                        this.loading = false
                        if (!this.stockTableData || this.stockTableData.length === 0) {
                            this.$message.error("暂无数据, 请等待数据填充!");
                        }
                    } else {
                        this.$message.error(res.msg)
                        this.toHome(this.receiveId)
                    }
                })
            } else {
                this.toHome()
            }
        },
        findReceiveStudent() {
            if (this.receiveId) {
                this.loading = true
                btnApi.findReceiveStudent(this.receiveId, this.queryParams).then(res => {
                    if (res.success) {
                        this.stockTableData = res.data.records
                        this.stockTotal = res.data.total
                        this.loading = false
                        // if (!this.stockTableData || this.stockTableData.length === 0) {
                        //     this.$message.error("无满足条件的数据！");
                        //     this.findStudent();
                        // }
                    } else {
                        if (res.code) {
                            // this.$message.error(res.msg)
                            this.stockTableData = []
                            this.stockTotal = 0;
                            this.loading = false
                        } else {
                            this.$message.error(res.msg)
                            this.toHome(this.receiveId)
                        }
                    }
                })
            } else {
                this.toHome()
            }
        },
        /**
         * 双呼拨打
         */
        callDoubleing(e, id) {
            api.doubleCall(id).then(res => {
                if (res.success) {
                    //隐藏当前点击的拨打按钮，显示挂断按钮
                    e.target.innerText = '已呼叫'
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.warning("拨打失败");
            })
        },
        /**
         * 拨打
         */
        calling(e, id) {
            api.calling(id).then(res => {
                if (res.success) {
                    //隐藏当前点击的拨打按钮，显示挂断按钮
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    let next = target.nextSibling;
                    target.setAttribute("hidden", true)
                    next.removeAttribute("hidden")
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("拨打失败");
            })
        },
        /**
         * 挂断
         */
        hangup(e) {
            api.hangUp().then(res => {
                if (res.success) {
                    //隐藏当前点击的挂断按钮，显示拨打按钮
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    let next = target.nextSibling;
                    target.setAttribute("hidden", true)
                    next.removeAttribute("hidden")
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("挂断失败");
            })
        },
        /**
         * 已拨打
         */
        haveCalling(e, id) {
            api.calling(id, 2).then(res => {
                if (res.success) {
                    //隐藏当前点击的挂断按钮，显示拨打按钮
                    let target = e.target
                    let tagName = target.tagName
                    if (tagName === 'SPAN') {
                        target = target.parentNode
                    }
                    let prev = target.previousSibling;
                    target.setAttribute("hidden", true)
                    prev.removeAttribute("hidden")
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("拨打失败");
            })
        },
        handleView(row) {
            studentApi.viewMobile(row.studentId).then(res => {
                if (res.success) {
                    row.mobile = res.data
                    row.showMobile = true
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        showTime(t) {
            if (t > 0) {
                let h = parseInt(t / 3600)
                h = h < 10 ? "0" + h : h;
                let m = parseInt(t / 60 % 60)
                m = m < 10 ? "0" + m : m;
                let s = parseInt(t % 60)
                s = s < 10 ? "0" + s : s;
                this.waitTimeStr = h + ':' + m + ':' + s
            }
        },
        getReceiveSize() {
            btnApi.getReceiveSize(this.receiveId).then(res => {
                this.receiveStatus = res.data
                let wait = this.receiveStatus.receiveWait
                if (wait > 0) {
                    this.waitTime = wait / 1000
                    clearInterval(this.timeSetInterval)
                    this.timeSetInterval = setInterval(() => {
                        if (this.waitTime > 0) {
                            this.waitTime = this.waitTime - 1
                        }
                    }, 1000)
                }
            })
        },
        beforeReceive(studentId) {
            this.receiveStudentId = studentId;
            if (this.receive.enableVerify && this.receive.enableVerify === true) {
                console.log('获取验证码')
                this.enableVerifyDialog = true;
                btnApi.getCaptcha().then(res => {
                    console.log(res)
                    this.captchaSrc = window.URL.createObjectURL(res)
                })
            }
        },
        closeEnableVerifyDialog() {
            this.captchaSrc = ''
            this.captchaInput = ''
            this.receiveStudentId = ''
            this.enableVerifyDialog = false;
        },
        submitEnableVerifyDialog() {
            btnApi.verifyCaptcha(this.captchaInput).then(res => {
                if (res.success) {
                    this.receiveStudent(this.receiveStudentId, false)
                    this.closeEnableVerifyDialog()
                } else {
                    this.$message.error("请输入正确的验证码")
                }
            })
        },
        /**
         * 领取机会
         * @param e 触发事件
         * @param studentId 机会Id
         * @param type 类型 1 拨打 2 领取 3 冻结
         */
        receiveStudent(studentId, verify) {
            //验证码领取
            // if (this.receive.enableVerify && verify) {
            //     this.beforeReceive(studentId)
            //     return;
            // }
            if (!this.isShowGetBtn(studentId)) {
                this.$message.warning('禁止重复领取');
                return
            }

            btnApi.receiveStudent(this.receiveId, this.servicesId, studentId).then(res => {
                if (res.success) {
                    this.showGetBtn.push(studentId)
                    this.getReceiveSize()
                    // this.findStudent()
                    this.$message.success(res.msg)
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("领取失败");
            })
        },
        next() {
            if (this.stockTotal === 0) {
                this.$message.warning("没有更多数据了")
            } else {
                this.nextQuery.btnType = 2
                this.findReceiveStudent()
            }
        },
        toHome(receiveId) {
            this.$store.dispatch('setHeaderTitle', "我的学员")
            let params = {};
            if (receiveId) {
                params['receiveId'] = receiveId;
            }
            this.$router.go(-1)
        },
    },
    computed: {
        isShowGetBtn() {
            return (id) => {
                return this.showGetBtn.indexOf(id) === -1
            }
        }
    },
    mounted() {
        this.servicesId = localStorage.getItem("servicesId")
        this.receiveId = this.$route.params.id
        this.findReceiveStudent()
        this.Index()
        if (this.receiveId) {
            this.getReceive()
            this.getReceiveSize()
        }
    }

}
</script>

<style scoped lang="less">

.custom--top-search-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  .search-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }

  .search-label {
    font-size: 12px;
    margin-right: 10px;
    width: 55px;
  }

  .search-input {
    width: 150px;
  }

  .search-btn {
    margin-left: 20px;
  }
}

.custom--top-btn-wrapper {
  .left-btn-group {
    width: 550px;
  }

  .right-btn-group {
    flex: 1;
    width: auto;
  }

  .custom--GgdCard {
    width: 160px;
  }
}


/deep/ .el-button--success {
  color: #FFF;
  background-color: #2f8e00;
  border-color: #2f8e00;
}

.frozen-button {
  margin-left: 10px;
}

/deep/ .el-radio {
  margin-right: 0;
}

</style>
